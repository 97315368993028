import * as $ from 'jquery';
import 'select2';
import * as Sentry from '@sentry-insurance/InternalFrontendTemplate';
import { InjectSpinner } from '@sentry-insurance/InternalFrontendTemplate';
import { CommafyValue, ConsoleLog, IsNumberKey, IsOnPhoneSizedScreen, IsOnSmallPhoneSizedScreen, RemoveCommas, RemoveLeadingZeros, VerboseConsoleLog, RemoveDollarSign } from '../src/js/shared';
import { PremiumCalculatorInputValues, PremiumCalculatorMachineLine, PremiumCalculatorResultMachineLine, PremiumCalculatorResultModel } from './Models/CalculatorModels';
import SentryModal from '@sentry-insurance/InternalFrontendTemplate/dist/Interfaces/SentryModal';


let CottonStrippersModal: SentryModal;

var UGPremCalc_DefaultConditionDropDown = true;
var UGPremCalc_DefaultMonthsFinanced = 12;
var UGPremCalc_NumItemsWhenClickAdd = 1;
var UGPremCalc_MaxNumAllowedItems = 50;
var UGPremCalc_MinMonthsFinanced = 1;
var UGPremCalc_MaxMonthsFinanced = 200;
var UGPremCalc_MaxCashPrice = 9999999;
var UGPremCalc_MaxNumCharsDescription = 250;
var UGPremCalc_ShowModalFor = '9999004,PremCalcCottonStripperAlert';

var UGPremCalc_PushInDefaultValuesForDebuggingPurposes = false; // WARNING: set to true to default some values for yourself, but BE SURE to set back to false!!
var UGPremCalc_DefaultSomethingReallySmall = false; // do you want to default 1 machine row, or a handful?
var UGPremCalc_MockSuccessOrFailure = false; // WARNING: set to true to create a mock success situation, but BE SURE to set back to false!!
var UGPremCalc_MockFailure = false; // set to true for failure, else it will be success!!


/// <summary>
/// Initialization function run at first load
/// </summary>
export function Init() {
    $(function () {
        // Initialization function run at first load of this page

        // Calculator component
        // --------------------------------------------------------
        InitializeCalculator();
        InjectSpinner($(".Spinner"));
        // --------------------------------------------------------
    });
}

function InitializeCalculator() {
    VerboseConsoleLog("InitializeCalculator");

    RemoveAllInvalid();
    EmptyResultsArea();

    // When the user clicks the find button, do some validations, and if valid, then either perform the finding of agents, or
    // navigate them to the find an agent page, passing along the query string values for the input controls.
    $('.PremiumCalculator .CalcButton a').click(function (evt) {
        VerboseConsoleLog('PremiumCalculator: Calculate button clicked');
        evt.preventDefault();
        $(this).blur();
        CalculatePremium();
    });


    // only allow number keys in the Months Financed field.
    $('.PremiumCalculator .InputPairing.MonthsFin input').keydown(function (evt) {
        //var charCode = (evt.which) ? evt.which : evt.keyCode;
        //ConsoleLog('keydown being handled: ' + charCode);
        if (!IsNumberKey(evt, false, false)) {
            evt.preventDefault();
        }
    });


    // If NOT on a phone, then we want the screen initialized with 5 total machine item rows, rather than 1
    if (!IsOnPhoneSizedScreen() && !IsOnSmallPhoneSizedScreen()) {
        // make 4 more copies of the item rows
        var idx = 1;
        while (idx <= 4) {
            AddMachineRow(false);
            idx += 1;
        }
    }

    // Now that ALL the rows are created let's hook up Select2 to the drop downs
    // Heads up that it appears that these config items do NOT work at all: dropdownAutoWidth, dropdownCssClass
    // minimumResultsForSearch works mostly, but doesn't for Condition, so we will just do it via CSS
    var equipLocation = $(".PremiumCalculator .EquipLoc");
    $("#EquipmentLocation", equipLocation).select2({
        placeholder: {
            id: '', // the value of the option
            text: 'Select'
        },
        selectOnClose: true, // automatically select the currently highlighted result when the dropdown is closed
        dropdownParent: equipLocation,
    });
    $.each($('.PremiumCalculator .MachineRows.Real .MachineRow'), function (index, element) {
        var MachineRow = $(element);
        SetUpSelect2ControlsForMachineRow(MachineRow);
    });
    

    // only allow numbers in the cash price controls
    $.each($('.PremiumCalculator .MachineRows.Real .MachineRow .CashPrice input'), function (index, element) {
        AddBehaviorsForCashPriceCtrl($(element));
    });

    // User clicks the print icon/link
    $('.PremiumCalculator .Print').click(function (evt) {
        VerboseConsoleLog('PremiumCalculator: Print button clicked');
        evt.preventDefault();
        $(this).blur();
        window.print();
    });

    // User clicks the "add another item" icon/text
    $('.PremiumCalculator .AddAnotherItem').click(function (evt) {
        VerboseConsoleLog('PremiumCalculator: Add More Rows button clicked');
        evt.preventDefault();
        $(this).blur();
        AddMoreRows();
    });

    // If you make a change to any cell (machine group, condition, or price) (except description), then in my opinion, that row's
    // corresponding premium cell in the last column should turn to "N/A" or blank, and the 2 totals in the right column should turn
    // to "N/A" or blank.  This will avoid somebody getting a premium calculated for a low/high price, then changing some values, and using
    // the print button to make it look like their premium should be much lower than what it may be.
    GetDropDownButtonCtrl($('.PremiumCalculator .EquipLoc')).change(function (e) {
        ResetPremiumAmountsInGridAndTotals();
    });
    $('.PremiumCalculator .InputPairing.MonthsFin input').change(function (e) {
        ResetPremiumAmountsInGridAndTotals();
    });
    var MachineRows = $('.PremiumCalculator .MachineRows.Real .MachineRow');
    $.each(MachineRows, function (index, element) {
        var MachineRow = $(element);
        WireOnChangeEventsForMachineRow(MachineRow);
    });

    
    // Set default value into Months Financed.
    $('.PremiumCalculator .InputPairing.MonthsFin input').val(UGPremCalc_DefaultMonthsFinanced);


    // Default the condition drop downs to select the "new" option
    setTimeout(function () {
        var conditionSelects = $('.PremiumCalculator .MachineRows.Real .MachineRow .Condition select');
        $.each(conditionSelects, function (index, element) {
            PossiblyDefaultConditionToNew($(element));
        });
    }, 20);


    if (UGPremCalc_PushInDefaultValuesForDebuggingPurposes) {
        // Testing code: make sure this is NOT ACTIVE in any upper environment
        SetupMockDefaultData();
    }
}

function CalculatePremium() {
    var SearchButton = $('.PremiumCalculator .CalcButton a');
    SearchButton.prop('disabled', true); // disable the button

    RemoveAllInvalid();
    RemoveGenericFailureMessage();

    // empty the results area
    EmptyResultsArea();

    if (!ValidateValues()) {
        SearchButton.prop('disabled', false); // re-enable the button
    }
    else {
        PossiblyShowAlertModal();

        // No matter if an alert is showing or not, still continue with the caculations
        ContinueCalculatingPremium();
    }
}

function ContinueCalculatingPremium() {
    var SearchButton = $('.PremiumCalculator .CalcButton a');

    ShowLoader();

    if (UGPremCalc_MockSuccessOrFailure) {
        // Testing code: make sure this is NOT running on an upper server
        MockSuccessOrFailure(!UGPremCalc_MockFailure);
        return;
    }

    // make an ajax call to perform the premium calculation, and if successful, write the results
    try {
        var inputValues = new PremiumCalculatorInputValues();

        inputValues.EquipmentLocation = GetDropDownValue($('.PremiumCalculator .EquipLoc'));
        inputValues.MonthsFinanced = parseInt(($('.PremiumCalculator .InputPairing.MonthsFin input').val() as string).trim());

        var machinesArray = [];
        var MachineRows = $('.PremiumCalculator .MachineRows.Real .MachineRow');
        $.each(MachineRows, function (index, element) {
            var MachineRow = $(element);
            if (DoesMachineRowHaveAnyValues(MachineRow)) {
                var enteredMachine = new PremiumCalculatorMachineLine();

                // Machine Group
                var MachineGroupValue = GetDropDownValue($('.MachineGroup', MachineRow));
                enteredMachine.ProductCode = MachineGroupValue;

                // Description, do not pass to the server, not used

                // Condition
                var ConditionValue = GetDropDownValue($('.Condition', MachineRow));
                enteredMachine.Condition = ConditionValue;

                // Cash Price
                var CashPrice = $('.CashPrice input', MachineRow);
                var CashPriceValue = GetTrueCashPriceValue(CashPrice);
                enteredMachine.CashPrice = parseFloat(CashPriceValue);

                machinesArray.push(enteredMachine);
            }
        });
        inputValues.Machines = machinesArray;


        WriteToGTMDataLayer(inputValues.EquipmentLocation,
            inputValues.MonthsFinanced,
            inputValues.Machines);

        VerboseConsoleLog("Passing this to the server: " + JSON.stringify(inputValues));

        $.ajax({
            type: "POST",
            url: "api/v1/Calculator/CalculatePremiums",
            timeout: 20 * 1000, // sets timeout to 20 seconds
            data: inputValues
        })
            .done(function (result) {
                ConsoleLog('DONE');
                ConsoleLog(result);

                var resultModel: PremiumCalculatorResultModel = undefined;
                try {
                    resultModel = result as PremiumCalculatorResultModel;
                } catch (e) {
                    ConsoleLog('PremiumCalculator: failed to construct a PremiumCalculatorResultModel from the result');
                    console.error(JSON.stringify(e));
                }

                if (resultModel.statusCode === 200) {
                    ConsoleLog('PremiumCalculator: SUCCESS!');
                    HandleSuccessSituation(resultModel);
                } else {
                    ConsoleLog('PremiumCalculator: FAILURE (status code not 200)!');
                    console.error(JSON.stringify(resultModel));
                    HandleErrorSituation(resultModel);
                }
            })
            .fail(function (result, textStatus, errorThrown) {
                ConsoleLog('PremiumCalculator: FAILURE!');
                console.error(result);
                AddGenericFailureMessage();
            })
            .always(function (result) {
                SearchButton.prop('disabled', false); // re-enable the button
                HideLoader();
            });
    }
    catch (err) {
        console.error(err);
        SearchButton.prop('disabled', false); // re-enable the button
        HideLoader();
        AddGenericFailureMessage();
    };
}

function SetupMockDefaultData() {
    // Testing code: make sure this is NOT ACTIVE in any upper environment
    setTimeout(function () {
        $('.PremiumCalculator .EquipLoc #EquipmentLocation').select2().val("Alabama").trigger("change");
        $('.PremiumCalculator .InputPairing.MonthsFin input').val('18');

        if (UGPremCalc_DefaultSomethingReallySmall) {
            // This will have some really small values, to create a situation where we see the forced monthly premium
            var machineRow = $('.PremiumCalculator .MachineRows.Real .MachineRow:first');
            //var machineGroupVal = 'Harvesting';
            $('.MachineGroup select', machineRow).select2().val("Harvesting").trigger("change");
            //$('.MachineGroup select', machineRow).select2('open');
            //$.each($('li.select2-results__option'), function (index, element) {
            //    console.log($(element).text().trim());
            //    if ($(element).text().trim() == machineGroupVal) {
            //        console.log('**** found it');
            //        setTimeout(function () { $(element).click(); }, 1);
            //    }
            //});
            $('.Description input', machineRow).val('Big Red, model #5667J');
            $('.Condition select', machineRow).select2().val("New").trigger("change");
            $('.CashPrice input', machineRow).val('1000');
        }
        else {
            var machineRow = $('.PremiumCalculator .MachineRows.Real .MachineRow:first');
            $('.MachineGroup select', machineRow).select2().val("Harvesting").trigger("change");
            $('.Description input', machineRow).val('Big Red, model #5667J');
            $('.Condition select', machineRow).select2().val("New").trigger("change");
            $('.CashPrice input', machineRow).val('32540');

            machineRow = $('.PremiumCalculator .MachineRows.Real .MachineRow:nth-child(2)');
            $('.MachineGroup select', machineRow).select2().val("Cotton strippers").trigger("change");
            $('.Description input', machineRow).val('Orange-build #6562');
            $('.Condition select', machineRow).select2().val("Used").trigger("change");
            $('.CashPrice input', machineRow).val('112900');

            machineRow = $('.PremiumCalculator .MachineRows.Real .MachineRow:nth-child(3)');
            $('.MachineGroup select', machineRow).select2().val("Other forestry equipment").trigger("change");
            $('.Description input', machineRow).val('RR-800.335');
            $('.Condition select', machineRow).select2().val("New").trigger("change");
            $('.CashPrice input', machineRow).val('230000');
        }
    }, 150);
};

function MockSuccessOrFailure(success: boolean) {
    var result = new PremiumCalculatorResultModel();

    var SearchButton = $('.PremiumCalculator .CalcButton a');
    SearchButton.prop('disabled', false); // re-enable the button

    HideLoader();

    RemoveGenericFailureMessage();

    if (success === true) {
        ConsoleLog('Hard coded success');

        result.monthlyPremium = "$750";
        result.totalPremium = "$9,000";

        result.ratedEquipmentList = [];

        var ratedEquipment1 = new PremiumCalculatorResultMachineLine();
        ratedEquipment1.lineNum = 1;
        ratedEquipment1.premiumAmount = "$110.10";
        result.ratedEquipmentList.push(ratedEquipment1);

        var ratedEquipment2 = new PremiumCalculatorResultMachineLine();
        ratedEquipment2.lineNum = 2;
        ratedEquipment2.premiumAmount = "$120.20";
        result.ratedEquipmentList.push(ratedEquipment2);

        result.contractMinimumApplied = true;

        HandleSuccessSituation(result);

    } else {
        ConsoleLog('Hard coded failure');

        result.statusCode = 400;

        result.errorMessage = "Required|Equipment Location";
        //result.ErrorMessage = "Required|Line Item|1|All Controls";
        //result.ErrorMessage = "Required|Line Item|1|Product Code";

        HandleErrorSituation(result);
    }
};

function HandleSuccessSituation(result: PremiumCalculatorResultModel) {
    // do something with the results
    $('.PremiumCalculator .MonthlyPremium').text(result.monthlyPremium + "*");
    $('.PremiumCalculator .TotalContractPremium').text(result.totalPremium + "*");

    // We also need to iterate through the RatedEquipmentList and with each one, find the row in the grid and put the
    // corresponding PremiumAmount into the last column cell, based on LineNum
    for (var i = 0; i < result.ratedEquipmentList.length; i++) {
        var lineNum = result.ratedEquipmentList[i].lineNum;
        var premiumValue = result.ratedEquipmentList[i].premiumAmount;

        // Try to find the row
        var MachineRows = $('.PremiumCalculator .MachineRows.Real .MachineRow');
        $.each(MachineRows, function (index, element) {
            var MachineRow = $(element);
            var PremiumAnswer = $('.Premium .Answer', MachineRow);
            if (DoesMachineRowHaveAnyValues(MachineRow) && (PremiumAnswer.text() == '' || PremiumAnswer.text() == 'NA' || PremiumAnswer.text() == 'N/A')) {
                PremiumAnswer.text(premiumValue);
                return false;
            }
        });
    }

    if (result.contractMinimumApplied === true) {
        $('.PremiumCalculator .ContractMinimumApplied').removeClass('Hide');
    }

    // Show the entire results area
    $('.PremiumCalculator .ResultsArea').removeClass('Hide');
};

function HandleErrorSituation(result: PremiumCalculatorResultModel) {
    // something went wrong, let's find out what
    var handledErrorMsg = false;

    var resultTxt = result.errorMessage;
    //try {
    //    resultTxt = result.getResponseHeader('ErrorMessage');
    //} catch (error) {
    //    ConsoleLog('Error was thrown without errormessage header');
    //    resultTxt = result.ErrorMessage;
    //}
    ConsoleLog(resultTxt);

    if (result.statusCode === 400) {
        var partsArr = resultTxt.split('|');
        if (partsArr.length == 2) {
            var message = partsArr[0];
            var control = partsArr[1].toLowerCase();
            if (control === "equipment location") {
                var EquipmentLocation = GetDropDownButtonCtrl($('.PremiumCalculator .EquipLoc'));
                AddInvalid(EquipmentLocation, message, true);
                handledErrorMsg = true;
            } else if (control === "months financed") {
                var MonthsFinanced = $('.PremiumCalculator .InputPairing.MonthsFin input');
                AddInvalid(MonthsFinanced, message, false);
                handledErrorMsg = true;
            }

        } else if (partsArr.length == 4) { // this means the line item(s)
            var message = partsArr[0];
            var what = partsArr[1].toLowerCase();
            var lineNum = partsArr[2];
            var controls = partsArr[3].toLowerCase();

            var MachineRows = $('.PremiumCalculator .MachineRows.Real .MachineRow');

            if (what === "line item") {
                if (lineNum.toLowerCase() == 'first' && controls == 'all controls') {
                    AddInvalidToAllMachineRowControls(MachineRows.first(), message);
                    handledErrorMsg = true;
                } else if (lineNum.toLowerCase() == 'last' && controls == 'all controls') {
                    var LastMachineRow = null;
                    $.each(MachineRows, function (index, element) {
                        if (DoesMachineRowHaveAnyValues($(element))) {
                            LastMachineRow = $(element);
                        }
                    });
                    if (LastMachineRow !== null) {
                        AddInvalidToAllMachineRowControls(LastMachineRow, message);
                        handledErrorMsg = true;
                    }
                } else { // must be a single row number
                    var lineNumInt = parseInt(lineNum, 10) - 1;
                    var filledRowsArray = [];
                    $.each(MachineRows, function (index, element) {
                        if (DoesMachineRowHaveAnyValues($(element))) {
                            filledRowsArray.push($(element));
                        }
                    });
                    if (controls == 'all controls') {
                        AddInvalidToAllMachineRowControls(filledRowsArray[lineNumInt], message);
                        handledErrorMsg = true;
                    } else if (controls == 'product code') {
                        AddInvalid(GetDropDownButtonCtrl($('.MachineGroup', filledRowsArray[lineNumInt])), message, true);
                        handledErrorMsg = true;
                    } else if (controls == 'condition') {
                        AddInvalid(GetDropDownButtonCtrl($('.Condition', filledRowsArray[lineNumInt])), message, true);
                        handledErrorMsg = true;
                    } else if (controls == 'cash price') {
                        AddInvalid(GetDropDownButtonCtrl($('.CashPrice input', filledRowsArray[lineNumInt])), message, false);
                        handledErrorMsg = true;
                    } else {
                        // Nothing we are prepared to handle right now
                    }
                }
            } else {
                // Nothing we are prepared to handle right now
            }
        } else {
            // Nothing we are prepared to handle right now
        }
    } else {
        // Nothing we are prepared to handle right now
    }

    if (!handledErrorMsg) {
        AddGenericFailureMessage();
    }
}

function EmptyResultsArea() {
    ResetPremiumAmountsInGridAndTotals();

    // Hide the entire results area
    $('.PremiumCalculator .ResultsArea').addClass('Hide');
}

function RemoveGenericFailureMessage() {
    $('.PremiumCalculator .GenericErrorMessage').text('');
    $('.PremiumCalculator .GenericErrorMessage').addClass('Hide');
}
function AddGenericFailureMessage() {
    $('.PremiumCalculator .GenericErrorMessage').removeClass('Hide');
    // TODO Maybe get this sentence from Contentful?
    // Do NOT set this piece of text in the HTML, rather have it populated HERE.  Reason?  We will have Dynatrace synthetic monitoring hooked up to look for
    // "An error occurred".  If it's in the DOM all the time (although hidden), the monitor will still see it and report a "down", so instead we will push
    // the value into the DOM element when it's time to show it.
    $('.PremiumCalculator .GenericErrorMessage').text('An error occurred. We are sorry for the inconvenience. Please let us know by calling us at 877-782-6873.');
}

function RemoveInvalid(selector: JQuery<HTMLElement>, forDropDown: boolean) {
    selector.parent().find('.error').remove();

    if (forDropDown) {
        $('.select2-selection', selector.parent()).removeClass('invalid');
    }
    else {
        selector.removeClass('invalid');
    }    
}
function AddInvalid(selector: JQuery<HTMLElement>, message: string, forDropDown: boolean) {
    RemoveInvalid(selector, forDropDown);

    selector.parent().append('<p class="error">' + message + '</p>');

    if (forDropDown) {
        $('.select2-selection', selector.parent()).addClass('invalid');
    }
    else {
        selector.addClass('invalid');
    }    
}

function AddInvalidToAllMachineRowControls(MachineRow: JQuery<HTMLElement>, message: string) {
    AddInvalid(GetDropDownButtonCtrl($('.MachineGroup', MachineRow)), message, true);
    AddInvalid($('.Description input', MachineRow), message, false);
    AddInvalid(GetDropDownButtonCtrl($('.Condition', MachineRow)), message, true);
    AddInvalid($('.CashPrice input', MachineRow), message, false);
};

function RemoveAllInvalid() {
    $('.PremiumCalculator p.error').remove();
    $('.PremiumCalculator .invalid').removeClass('invalid');
}

function ValidateValues() {
    VerboseConsoleLog('PremiumCalculator: Validating entered values');
    var isValid = true;

    var BaseRequiredMessage = $('.PremiumCalculator .BaseRequiredMessage').text();
    var BaseInvalidMessage = $('.PremiumCalculator .BaseInvalidMessage').text();
    var BaseCannotMoreThanMessage = $('.PremiumCalculator .BaseCannotMoreThanMessage').text();
    var BaseCannotLessThanMessage = $('.PremiumCalculator .BaseCannotLessThanMessage').text();
    var BaseMessageAtLeast1MachineRow = $('.PremiumCalculator .BaseMessageAtLeast1MachineRow').text();

    // Machine Line Items
    var MachineRows = $('.PremiumCalculator .MachineRows.Real .MachineRow');
    var NumFilledRows = 0;
    $.each(MachineRows, function (index, element) {
        var MachineRow = $(element);
        if (DoesMachineRowHaveAnyValues(MachineRow)) {
            NumFilledRows++;
        }
    });
    if (NumFilledRows == 0) {
        var FirstRow = $('.PremiumCalculator .MachineRows.Real li').first();
        AddInvalid(GetDropDownButtonCtrl($('.MachineGroup', FirstRow)), BaseMessageAtLeast1MachineRow, true);
        isValid = false;
    } else if (NumFilledRows > UGPremCalc_MaxNumAllowedItems) {
        // This should never really happen, but let's be very careful and stop any hacker from doing any bad
        var LastValidRow = $('.PremiumCalculator .MachineRows.Real li').last();
        AddInvalid(GetDropDownButtonCtrl($('.MachineGroup', LastValidRow)), BaseCannotMoreThanMessage + UGPremCalc_MaxNumAllowedItems + ' machine rows.', true);
        isValid = false;
    } else {
        $.each(MachineRows, function (index, element) {
            var MachineRow = $(element);

            if (DoesMachineRowHaveAnyValues(MachineRow)) {
                // Machine Group - required
                var MachineGroupValue = GetDropDownValue($('.MachineGroup', MachineRow));
                if (MachineGroupValue === '') {
                    AddInvalid(GetDropDownButtonCtrl($('.MachineGroup', MachineRow)), BaseRequiredMessage, true);
                    isValid = false;
                }

                // Description - not required, but has a max number of chars
                var Description = $('.Description input', MachineRow);
                if ((Description.val() as string).length > UGPremCalc_MaxNumCharsDescription) {
                    AddInvalid(Description, BaseCannotMoreThanMessage + UGPremCalc_MaxNumCharsDescription + ' chars.', false);
                    isValid = false;
                }

                // Condition - required
                var ConditionValue = GetDropDownValue($('.Condition', MachineRow));
                if (ConditionValue === '') {
                    AddInvalid(GetDropDownButtonCtrl($('.Condition', MachineRow)), BaseRequiredMessage, true);
                    isValid = false;
                }

                // Cash Price
                var CashPrice = $('.CashPrice input', MachineRow);
                var CashPriceValue = GetTrueCashPriceValue(CashPrice);
                if (CashPriceValue == '') {
                    AddInvalid(CashPrice, BaseRequiredMessage, false);
                    isValid = false;
                } else if (isNaN(parseFloat(CashPriceValue))) {
                    AddInvalid(CashPrice, BaseInvalidMessage, false);
                    isValid = false;
                } else {
                    if (parseFloat(CashPriceValue) < 0) {
                        AddInvalid(CashPrice, BaseCannotLessThanMessage + '$0', false);
                        isValid = false;
                    }
                    if (parseFloat(CashPriceValue) > UGPremCalc_MaxCashPrice) {
                        AddInvalid(CashPrice, BaseCannotMoreThanMessage + '$' + UGPremCalc_MaxCashPrice, false);
                        isValid = false;
                    }
                }
            }
        });
    }


    // Equipment Location
    var EquipLoc = $('.PremiumCalculator .EquipLoc');
    var EquipLocVal = GetDropDownValue(EquipLoc);
    if (EquipLocVal === '') {
        AddInvalid(GetDropDownButtonCtrl(EquipLoc), BaseRequiredMessage, true);
        isValid = false;
    }


    // Months Financed
    var MonthsFinanced = $('.PremiumCalculator .InputPairing.MonthsFin input');
    var MonthsFinancedVal = MonthsFinanced.val() as string;
    if (MonthsFinancedVal == '') {
        AddInvalid(MonthsFinanced, BaseRequiredMessage, false);
        isValid = false;
    } else if (isNaN(parseInt(MonthsFinancedVal))) {
        AddInvalid(MonthsFinanced, BaseInvalidMessage, false);
        isValid = false;
    } else {
        if (parseInt(MonthsFinancedVal) < UGPremCalc_MinMonthsFinanced) {
            AddInvalid(MonthsFinanced, BaseCannotLessThanMessage + UGPremCalc_MinMonthsFinanced, false);
            isValid = false;
        }
        if (parseInt(MonthsFinancedVal) > UGPremCalc_MaxMonthsFinanced) {
            AddInvalid(MonthsFinanced, BaseCannotMoreThanMessage + UGPremCalc_MaxMonthsFinanced, false);
            isValid = false;
        }
    }

    if (isValid === true) { VerboseConsoleLog('PremiumCalculator: all valid'); }
    else { VerboseConsoleLog('PremiumCalculator: woops, something is not valid!'); }

    return isValid;
}

function PossiblyShowAlertModal(): boolean {
    VerboseConsoleLog('PremiumCalculator: possibly showing an alert based on entered values');

    var modalsToShow = [];

    // Machine Line Items - show alert if they choose Cotton Strippers
    var MachineRows = $('.PremiumCalculator .MachineRows.Real .MachineRow');
    $.each(MachineRows, function (index, element) {
        var MachineRow = $(element);

        if (DoesMachineRowHaveAnyValues(MachineRow)) {
            var MachineGroupValue = GetDropDownValue($('.MachineGroup', MachineRow));

            // each option is split by a |
            var ModalOptions = UGPremCalc_ShowModalFor.split("|");
            for (var i = 0; i < ModalOptions.length; i++) {
                // in each option, the driving-force value and the modal id is split by comma
                var ModalOptionValues = ModalOptions[i].split(",");
                if (MachineGroupValue.toLowerCase() === ModalOptionValues[0].toLowerCase()) {
                    // user selected a value that should show a modal, add it to the list of modals, assuming its not there already
                    if (modalsToShow.indexOf(ModalOptionValues[1]) == -1) {
                        modalsToShow.push(ModalOptionValues[1]);
                    }
                }
            }
        }
    });

    if (modalsToShow.length == 0) { return false; }

    for (var i = 0; i < modalsToShow.length; i++) {
        OpenModalDialog(modalsToShow[i]);
    }

    return true;
}

function OpenModalDialog(modalDialogID: string) {
    VerboseConsoleLog('OpenModalDialog');

    if (modalDialogID == 'PremCalcCottonStripperAlert') {
        CloseAllModalDialogs();
        //CottonStrippersModal?.modal('dispose').remove();
        CottonStrippersModal = Sentry.ShowModalCustom("Alert",
            /* Content */
            "<p>We calculate cotton stripper rates based on the CS690 model.  If you want a quote for a 7460 cotton stripper, please contact your John Deere Financial representative directly for an accurate rate.</p>" +
            /* Buttons */
            "<button type='button' class='CTA Button BlueWhite z-depth-0' data-dismiss='modal'>Continue</button>"
        );
    }
    else {
        ConsoleLog('Unknown Modal Dialog ID to show: ' + modalDialogID);
    }
}

function CloseAllModalDialogs() {
    VerboseConsoleLog('CloseAllModalDialogs being called');
    Sentry.HideAllModals();
}

function GetDropDownValue(OuterElem: JQuery<HTMLElement>) {
    var selectedItem = $('select', OuterElem).select2('data');
    //ConsoleLog('selectedItem = ' + JSON.stringify(selectedItem));
    if (selectedItem === undefined || selectedItem.length == 0 || selectedItem[0].id == 'Select') { return ''; }
    var activeDataValue = $('select option[data-display-name="' + selectedItem[0].id + '"]', OuterElem).attr('data-value');
    if (activeDataValue === undefined) { activeDataValue = ''; }
    //ConsoleLog('activeDataValue = ' + activeDataValue);
    return activeDataValue;
}

function GetDropDownButtonCtrl(OuterElem: JQuery<HTMLElement>) {
    return $('select', OuterElem);
}

function PossiblyDefaultConditionToNew(selectCtrl: JQuery<HTMLElement>)
{
    // Default the condition drop downs to select the "new" option
    if (UGPremCalc_DefaultConditionDropDown === true) {
        setTimeout(function () {
            selectCtrl.select2().val("New").trigger("change");
        }, 20);
    }
}

function DoesMachineRowHaveAnyValues(MachineRow: JQuery<HTMLElement>) {
    var MachineGroupValue = GetDropDownValue($('.MachineGroup', MachineRow));
    var DescriptionValue = $('.Description input', MachineRow).val() as string;
    var ConditionValue = GetDropDownValue($('.Condition', MachineRow));
    var CashPriceValue = GetTrueCashPriceValue($('.CashPrice input', MachineRow));

    if (UGPremCalc_DefaultConditionDropDown === true) {
        // Condition is no longer considered something that triggers a row to be "entered" by the user, since we are now 
        return (MachineGroupValue !== '' || DescriptionValue.length > 0 || CashPriceValue.length > 0);
    }
    else {
        return (MachineGroupValue !== '' || DescriptionValue.length > 0 || ConditionValue !== '' || CashPriceValue.length > 0);
    }
}

function WriteToGTMDataLayer(EquipmentLocation: string, MonthsFinanced: number, Machines: PremiumCalculatorMachineLine[]) {
    try {
        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({
            'event': 'PremiumCalculation',
            'equipmentLocation': EquipmentLocation,
            'monthsFinanced': MonthsFinanced,
            'machines': Machines
        });
    }
    catch (innerErr) {
        console.error('Failed to push to the GTM dataLayer: ' + innerErr.message);
    }
}

function SetUpSelect2ControlsForMachineRow(MachineRow: JQuery<HTMLElement>) {
    var MachineGroup = $('.MachineGroup', $(MachineRow));
    $("select", MachineGroup).select2({
        placeholder: {
            id: '', // the value of the option
            text: 'Select'
        },
        selectOnClose: true, // automatically select the currently highlighted result when the dropdown is closed
        dropdownParent: MachineGroup,
    });

    var Condition = $('.Condition', $(MachineRow));
    $("select", Condition).select2({
        placeholder: {
            id: '', // the value of the option
            text: 'Select'
        },
        selectOnClose: true, // automatically select the currently highlighted result when the dropdown is closed
        dropdownParent: Condition,
    });
}

function AddMachineRow(addOnChangeBehaviorsForNewRow: boolean) {
    var toBeCloned = $('.PremiumCalculator .MachineRows.ToBeCloned .MachineRow')
    var clone = toBeCloned.clone(true);
    clone.appendTo($('.PremiumCalculator .MachineRows.Real'));

    $('.ItemHeading .Num', clone).text($('.PremiumCalculator .MachineRows.Real .MachineRow').length);

    AddBehaviorsForCashPriceCtrl($('.CashPrice input', clone));

    if (addOnChangeBehaviorsForNewRow === true) {
        SetUpSelect2ControlsForMachineRow(clone);

        WireOnChangeEventsForMachineRow(clone);

        var conditionSelect = $('.Condition select', clone);
        PossiblyDefaultConditionToNew(conditionSelect);
    }
}

function AddMoreRows() {
    VerboseConsoleLog('PremiumCalculator: possibly adding more machine rows');

    var CurrentNumberRows = $('.PremiumCalculator .MachineRows.Real .MachineRow').length;

    if (CurrentNumberRows < UGPremCalc_MaxNumAllowedItems) {
        // Let's make sure that we don't go over the limit
        if ((CurrentNumberRows + UGPremCalc_NumItemsWhenClickAdd) > UGPremCalc_MaxNumAllowedItems) {
            VerboseConsoleLog('PremiumCalculator: dont go over the max row limit');
            UGPremCalc_NumItemsWhenClickAdd = (UGPremCalc_MaxNumAllowedItems - CurrentNumberRows);
            $('.PremiumCalculator .AddAnotherItem').hide();
        }
        VerboseConsoleLog('PremiumCalculator: adding ' + UGPremCalc_NumItemsWhenClickAdd + ' row(s)');

        var counter = 1;
        while (counter <= UGPremCalc_NumItemsWhenClickAdd) {
            AddMachineRow(true);
            counter += 1;
            CurrentNumberRows++;
        }
        VerboseConsoleLog('PremiumCalculator: now there should be ' + $('.PremiumCalculator .MachineRows.Real li').length + ' rows');

        if (CurrentNumberRows >= UGPremCalc_MaxNumAllowedItems) {
            VerboseConsoleLog('PremiumCalculator: dont go over the max row limit');
            $('.PremiumCalculator .AddAnotherItem').hide();
        }
    } else {
        VerboseConsoleLog('PremiumCalculator: there are already enough machine rows');
        $('.PremiumCalculator .AddAnotherItem').hide();
    }
}

function AddBehaviorsForCashPriceCtrl(elem: JQuery<HTMLElement>) {
    $(elem).keydown(function (evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        //SentryUtility.ConsoleLog('keydown being handled: ' + charCode);
        if (!IsNumberKey(evt, false, false)) {
            evt.preventDefault();
        }
    });

    $(elem).focus(function () {
        $(this).val(GetTrueCashPriceValue($(this)));
    });

    $(elem).focusout(function () {
        // put the value back into a nice display-like form
        $(this).val(CreateReadableCashPriceValue($(this).val() as string));
    });
}

function GetTrueCashPriceValue(elem: JQuery<HTMLElement>) {
    var fieldVal = $(elem).val() as string;
    //SentryUtility.ConsoleLog('fieldVal: ' + fieldVal);
    if (fieldVal.length == 0 || fieldVal == '' || fieldVal == '0' || fieldVal == '$0') {
        return '';
    } else {
        fieldVal = RemoveDollarSign(fieldVal);
        fieldVal = RemoveCommas(fieldVal);
        return fieldVal;
    }
}

function CreateReadableCashPriceValue(fieldVal: string): string {
    if (fieldVal == '') { return; }
    fieldVal = RemoveCommas(fieldVal);
    fieldVal = RemoveLeadingZeros(fieldVal);
    fieldVal = "$" + CommafyValue(fieldVal);
    if (fieldVal.startsWith('$$')) { fieldVal = fieldVal.replace('$$', '$'); }
    return fieldVal;
}

function WireOnChangeEventsForMachineRow(MachineRow: JQuery<HTMLElement>) {
    // Machine Group
    GetDropDownButtonCtrl($('.MachineGroup', MachineRow)).change(function (e) {
        ResetPremiumAmountsInGridAndTotals();
    });

    // Description, do not worry about if this is changed, it's purely for the printing aspect

    // Condition
    GetDropDownButtonCtrl($('.Condition', MachineRow)).change(function (e) {
        ResetPremiumAmountsInGridAndTotals();
    });

    // Cash Price
    $('.CashPrice input', MachineRow).change(function (e) {
        ResetPremiumAmountsInGridAndTotals();
    });
}

function ResetPremiumAmountsInGridAndTotals() {
    // Grid first
    var MachineRows = $('.PremiumCalculator .MachineRows.Real .MachineRow');
    $.each(MachineRows, function (index, element) {
        var MachineRow = $(element);
        $('.Premium .Answer', MachineRow).text('NA');
    });

    // Totals next
    $('.PremiumCalculator .MonthlyPremium').text('NA*');
    $('.PremiumCalculator .TotalContractPremium').text('NA*');

    $('.PremiumCalculator .ContractMinimumApplied').addClass('Hide');

    // Hide the entire results area
    $('.PremiumCalculator .ResultsArea').addClass('Hide');
}

function ShowLoader() {
    $('body').addClass('show-spinner');
    $('body').addClass('stop-scroll');
    $('.spinner-backdrop').toggleClass('Show').toggleClass('Hide');
}
function HideLoader() {
    $('body').removeClass('show-spinner');
    $('body').removeClass('stop-scroll');
    $('.spinner-backdrop').toggleClass('Show').toggleClass('Hide');
}