export class PremiumCalculatorInputValues {
    EquipmentLocation: string;
    MonthsFinanced: number;
    Machines: PremiumCalculatorMachineLine[] = [];
}
export class PremiumCalculatorMachineLine {
    ProductCode: string;
    Condition: string;
    CashPrice: number;
}


export class PremiumCalculatorResultModel {
    statusCode: number;
    errorMessage: string;
    monthlyPremium: string;
    totalPremium: string;
    ratedEquipmentList: PremiumCalculatorResultMachineLine[] = [];
    contractMinimumApplied: boolean;
}
export class PremiumCalculatorResultMachineLine {
    lineNum: number;
    premiumAmount: string;
}